
import { defineComponent } from "vue-demi";
import FinanceMain from "@/components/profile/finance/index.vue";

export default defineComponent({
  name: "FinanceView",
  components: {
    FinanceMain,
  },
});
